import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[uppercase]'
})
export class UppercaseDirective {

  constructor(public el: ElementRef) { }

  @HostListener('keyup') onKeyUp() {
    this.el.nativeElement.value = this.el.nativeElement.value.toUpperCase();
  }

}
