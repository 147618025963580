import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';
import { Empresa } from './../../model/empresa';
import { EmpresasService } from './../../services/empresas.service';

@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.scss']
})
export class EmpresasComponent implements OnInit {

  empresasArray: Empresa[];
  imgBucket = `${environment.imgBucket}`;

  constructor(private empresaService: EmpresasService,
              private ngxService: NgxUiLoaderService) { }

  ngOnInit() {
    this.ngxService.start();
    this.empresaService.getEmpresas().subscribe(res => {
      this.empresasArray = res.body;
      this.ngxService.stop();
    }, err => {
      console.log('Error al obtener las empresas' + err);
      this.ngxService.stop();
    });
  }

}
