import { DeclinarService } from './../../services/declinar.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Becario } from 'src/app/model/becario';
import { Declinacion } from 'src/app/model/declinacion';
import { ValidateUserService } from 'src/app/services/validate-user.service';
import { CURPValidator } from 'src/app/util/curp.validator';
import { MotivoDeclinacion } from './../../model/motivoDeclinacion';
import { MotivoDeclinacionService } from './../../services/motivo-declinacion.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-declinacion',
  templateUrl: './declinacion.component.html',
  styleUrls: ['./declinacion.component.scss']
})
export class DeclinacionComponent implements OnInit {

  @ViewChild('notFoundSwal') private notFoundSwal: SwalComponent;
  @ViewChild('notEnabledSwal') private notEnabledSwal: SwalComponent;
  @ViewChild('declinaOkSwal') private declinaOkSwal: SwalComponent;
  @ViewChild('errorDeclinaSwal') private errorDeclinaSwal: SwalComponent;

  folioForm: FormGroup;
  declinacionForm: FormGroup;

  bucketUrl = `${environment.imgBucket}`;

  submitedFolio: boolean;
  submitedDeclinacion: boolean;
  validated: boolean;
  buttonDisabled: boolean;
  folio: number;
  becario: Becario;
  mdArray: MotivoDeclinacion[];

  constructor(private formBuilder: FormBuilder,
    private ngxService: NgxUiLoaderService,
    private motivoDeclinacionService: MotivoDeclinacionService,
    private declinarService: DeclinarService,
    private serviceValidateUser: ValidateUserService,
    private router: Router) { }

  ngOnInit() {
    this.ngxService.start();
    // Form de folio y CURP
    this.folioForm = this.formBuilder.group({
      fieldFolio: ['', Validators.required],
      fieldCURP: ['', Validators.required]
    }, {
        validators: CURPValidator('fieldCURP')
      });

    // Form de declinacion
    this.declinacionForm = this.formBuilder.group({
      fieldMotivo: ['', Validators.required],
      fieldComentarios: ['', Validators.required],
      fieldAviso: ['', Validators.requiredTrue]
    });

    this.motivoDeclinacionService.getMotivosDeclinacion().subscribe(
      res => {
        this.mdArray = res.body;
        this.ngxService.stop();
      },
      err => {
        console.log('Error al obtener los motivos de declinación');
        this.ngxService.stop();
      }
    );
  }

  declinar() {
    this.submitedDeclinacion = true;
    if (this.declinacionForm.invalid) {
      return;
    } else {
      this.ngxService.start();
      const declinacion = new Declinacion();
      declinacion.folio = this.folioForm.get('fieldFolio').value;
      declinacion.idMotivo = this.declinacionForm.get('fieldMotivo').value;
      declinacion.comentarios = this.declinacionForm.get('fieldComentarios').value;

      this.declinarService.declinar(declinacion).subscribe(res => {
        console.log(res.body);
        this.ngxService.stop();
        this.declinaOkSwal.show();
      },
        err => {
          console.log(err);
          this.ngxService.stop();
          this.errorDeclinaSwal.show();
        });
    }
  }

  validate() {
    this.submitedFolio = true;

    if (this.folioForm.invalid) {
      return;
    } else {
      this.ngxService.start();
      this.folio = this.folioForm.get('fieldFolio').value;
      this.serviceValidateUser.validateUser(this.folio,
        this.folioForm.get('fieldCURP').value).subscribe(res => {

          if (res.status === 200) {

            this.becario = res.body;

            // Valida que el folio ingresado esté en estatud 2 (Nuevo)
            if (this.becario.estatusPostulacion === 2 || this.becario.estatusPostulacion === 3) {
              this.validated = true;

              // Disable components
              this.folioForm.get('fieldFolio').disable();
              this.folioForm.get('fieldCURP').disable();
              this.buttonDisabled = true;
              this.ngxService.stop();
            } else {
              this.ngxService.stop();
              this.notEnabledSwal.show();
            }

          } else {
            this.ngxService.stop();
            this.notFoundSwal.show();
          }
        }, (err) => {
          console.log(err);
        });
    }
  }

  faqRedirect() {
    this.router.navigateByUrl('/preguntas-frecuentes');
  }

  get f() { return this.folioForm.controls; }

  get d() { return this.declinacionForm.controls; }


}
