import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telefono'
})
export class TelefonoPipe implements PipeTransform {

  transform(telefono: string, args?: any): string {
    if (telefono != null && telefono.length === 10) {
      const telefono1 = telefono.substr(0, 2) + ' ' + telefono.substr(2, 4) + ' ' + telefono.substr(6, 4);
      return telefono1;
    } else {
      return telefono;
    }
  }

}
