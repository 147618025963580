import { Contacto } from './../model/contacto';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactoService {

  apiURL = `${environment.apiURL}user/becario/contacto`;

  constructor(private http: HttpClient) { }

  createContacto(contacto: Contacto) {
    return this.http.post(this.apiURL, contacto, {observe: 'response'});
  }
}
