import { Injectable } from '@angular/core';
import { Empresa } from '../model/empresa';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmpresasService {

  apiURL = `${environment.apiURL}catalogos/empresas`;

  constructor(private http: HttpClient) { }

  getEmpresas() {
    const data = {};
    return this.http.post<Empresa[]>(this.apiURL, data, {observe: 'response'});
  }
}
