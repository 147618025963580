import { Telefono } from './telefono';
import { Empresa } from './empresa';
import { Direccion } from './direccion';
import { Tutor } from './tutor';

export class Becario {
  idUsuario: number;
  idPerfil: number;
  folio: number;
  idEstatus: number;
  curp: string;
  nombre: string;
  apaterno: string;
  amaterno: string;
  correo: string;
  fechaNacimiento: Date;
  genero: number;
  celular: string;
  selfie: string;
  direccionCompleta: string;
  nombreCompleto: string;
  telefono1: Telefono;
  telefono2: Telefono;
  contacto: string;
  empresa: Empresa;
  direccion: Direccion;
  nombreCentro: string;
  avisoPrivacidad: number;
  estatusPostulacion: number;
  idPostulacion: number;
  idCentro: number;
  fechaInicioCap: Date;
  tutor: Tutor;
}
