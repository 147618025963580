import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Contacto } from 'src/app/model/contacto';
import { ContactoService } from 'src/app/services/contacto.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {

  phoneMask = [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
  folioMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

  contactoForm: FormGroup;
  submitedContacto = false;

  @ViewChild('contactoOkSwal') private contactoOkSwal: SwalComponent;
  @ViewChild('errorContactoSwal') private errorContactoSwal: SwalComponent;

  constructor(private formBuilder: FormBuilder,
              private ngxService: NgxUiLoaderService,
              private service: ContactoService,
              private router: Router) { }

  ngOnInit() {
    this.contactoForm = this.formBuilder.group({
      fieldNombre: ['', Validators.required],
      fieldCorreo: ['', Validators.compose([Validators.required, Validators.email])],
      fieldTelefono: ['', Validators.required],
      fieldFolio: ['', Validators.required],
      fieldComentarios: ['', Validators.required],
    });
  }

  send() {
    this.submitedContacto = true;
    if (this.contactoForm.invalid) {
      return;
    } else {
      this.ngxService.start();
      this.service.createContacto(this.getContactoObj()).subscribe(
        res => {
          console.log(res.body);
          this.ngxService.stop();
          this.contactoOkSwal.show();
        }, err => {
          console.log(err);
          this.ngxService.stop();
          this.errorContactoSwal.show();
        }
      );
    }
  }

  faqRedirect() {
    this.router.navigateByUrl('/preguntas-frecuentes');
  }

  getContactoObj() {
    const contacto = new Contacto();
    contacto.nombre = this.contactoForm.get('fieldNombre').value;
    contacto.correo = this.contactoForm.get('fieldCorreo').value;
    contacto.telefono = this.contactoForm.get('fieldTelefono').value;
    contacto.folio = this.contactoForm.get('fieldFolio').value;
    contacto.comentarios = this.contactoForm.get('fieldComentarios').value;

    return contacto;
  }

  get f() { return this.contactoForm.controls; }

}
