import { Becario } from './../model/becario';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ValidateUserService {

  apiURL = `${environment.apiURL}user/becario/folio-curp`;

  constructor(private http: HttpClient) { }

  validateUser(folio: number, curp: string) {
    const data = { folio: folio, curp: curp };
    return this.http.post<Becario>(this.apiURL, data, {observe: 'response'});
  }

}
