import {Component, OnInit} from '@angular/core';
import {PreguntasFrecuentesServiceService} from 'src/app/services/preguntas-frecuentes-service.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-preguntas-frecuentes',
  templateUrl: './preguntas-frecuentes.component.html',
  styleUrls: ['./preguntas-frecuentes.component.scss']
})
export class PreguntasFrecuentesComponent implements OnInit {
  pfs: Array<object>;

  constructor(private  pf: PreguntasFrecuentesServiceService, private ngxService: NgxUiLoaderService) {

  }

  ngOnInit() {
    this.ngxService.start();
    this.pf.getPreguntasFrecuentes().subscribe(res => {
      if (res.status === 200) {
        this.pfs = res.body;
        this.ngxService.stop();
      }
    }, (err) => {
      console.log(err);
    });
  }
}
