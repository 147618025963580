import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Becario } from './../model/becario';

@Injectable({
  providedIn: 'root'
})
export class CorreoService {

  apiURL = `${environment.apiURL}notificaciones/mail`;

  constructor(private http: HttpClient) { }

  createContacto(becario: Becario) {
    let tutor: string;
    let correoTutor: string;
    let correoBecario: string;

    if (becario.tutor.nombreCompleto) {
      tutor = becario.tutor.nombreCompleto;
    } else {
      tutor = becario.tutor.nombre + ' ' + becario.tutor.aPaterno + ' ' + becario.tutor.aMaterno;
    }

    if (`${environment.production}`) {
       correoTutor = becario.tutor.correo;
       correoBecario = becario.correo;
    } else {
      correoTutor = 'ramhernandezc@gmail.com';
      correoBecario = 'ramhernandezc@gmail.com';
    }

    const params = {
      correo: correoBecario,
      opcion: '1',
      nombreBecario: becario.nombreCompleto,
      correoTutor,
      fchCapacitacion: becario.fechaInicioCap,
      vacantes: this.compareDates(new Date(), new Date(becario.fechaInicioCap)),
      nombreCentro: becario.nombreCentro,
      nombreTutor: tutor
    };
    return this.http.post(this.apiURL, params, { observe: 'response' });
  }

  private compareDates(date1: Date, date2: Date) {
    const oneDay = 1000 * 60 * 60 * 24;
    const date1Ms: number = date1.getMilliseconds();
    const date2Ms = date2.getMilliseconds();

    const millisDifference = date2Ms - date1Ms;

    return Math.round(millisDifference / oneDay);

  }
}
