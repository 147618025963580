export class Contacto {
  idComentario: number;
  nombre: string;
  correo: string;
  telefono: string;
  folio: number;
  comentarios: string;
  usuarioModifico: string;
  ultimaModificacion: Date;
}
