import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Entidad } from '../model/entidad';

@Injectable({
  providedIn: 'root'
})
export class EntidadesService {

  apiURL = `${environment.apiURL}catalogos/entidades`;

  constructor(private http: HttpClient) { }

  getEntidades() {
    const data = {};
    return this.http.post<Entidad[]>(this.apiURL, data, {observe: 'response'});
  }

}
