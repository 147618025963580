// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // dev
  //apiURL: 'https://71bnbidi13.execute-api.us-east-1.amazonaws.com/v1/api/jcf/',
  //captchaKey: '6LdxRJ0UAAAAAGVUnu90axQ-L0LKmt5I-EJoFw78',
  // qa
  //apiURL: 'https://jprodgl6s1.execute-api.us-east-1.amazonaws.com/v1/api/jcf/',
  //captchaKey: '6Le1Yq8UAAAAAAAe8HMGw4yH_UAsp9hZ2VZf8XlJ',  
  // prod
  apiURL: 'https://7n0s15ab5m.execute-api.us-east-1.amazonaws.com/v1/api/jcf/',
  captchaKey: '6Lezk7AUAAAAAE3-rcMXAy5jvF6Fpuk6ol_g66g0',

  imgBucket: 'https://s3.amazonaws.com/upax-conekta-dev/imagenes-noticias/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.