import { FormGroup } from '@angular/forms';

export function CURPValidator(curp: string) {
  return (formGroup: FormGroup) => {
    const fieldCURP = formGroup.controls[curp];

    if(fieldCURP.errors && !fieldCURP.errors.validCurp) {
      return;
    }

    if (curpValida(fieldCURP.value.toUpperCase())) {
      fieldCURP.setErrors(null);
    } else {
      fieldCURP.setErrors({validCurp: true});
    }

  }
}

function curpValida(curp) {

  var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0\d|1[0-2])(?:[0-2]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
    validado = curp.match(re);
    return validado;
}
