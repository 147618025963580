import { Becario } from './../model/becario';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CitaService {

  apiURL = `${environment.apiURL}user/becario/cita`;

  constructor(private http: HttpClient) { }

  createCita(becario: Becario) {
    return this.http.post(this.apiURL, becario, {observe: 'response'});
  }

}

