import { CorreoService } from './../../services/correo.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Direccion } from 'src/app/model/direccion';
import { Telefono } from 'src/app/model/telefono';
import { CitaService } from 'src/app/services/cita.service';
import { ValidateUserService } from 'src/app/services/validate-user.service';
import { CURPValidator } from 'src/app/util/curp.validator';
import { environment } from 'src/environments/environment';
import { Becario } from './../../model/becario';
import { Entidad } from './../../model/entidad';
import { EntidadesService } from './../../services/entidades.service';

@Component({
  selector: 'app-cita',
  templateUrl: './cita.component.html',
  styleUrls: ['./cita.component.scss']
})
export class CitaComponent implements OnInit {

  phoneMask = [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
  cpMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
  folioMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];


  phoneOpts: string[] = ['Casa', 'Móvil', 'Otro'];
  parentalOpts: string[] = ['Padre', 'Madre', 'Tío', 'Hermano', 'Otro'];
  entidadesArray: Entidad[];
  validated = false;
  submitedFolio = false;
  submitedCita = false;
  buttonDisabled = false;
  captchaKey = `${environment.captchaKey}`;
  bucketUrl = `${environment.imgBucket}`;
  folio: number;
  robot = true;

  folioForm: FormGroup;
  citaForm: FormGroup;

  becario: Becario;
  businessUnit: string;
  center: string;
  msgAlert: string;
  idPostulacion: number;
  idCentro: number;

  @ViewChild('notFoundSwal') private notFoundSwal: SwalComponent;
  @ViewChild('notEnabledSwal') private notEnabledSwal: SwalComponent;
  @ViewChild('citaOkSwal') private citaOkSwal: SwalComponent;
  @ViewChild('citaSwal') private citaSwal: SwalComponent;
  @ViewChild('declinadoSwal') private declinadoSwal: SwalComponent;
  @ViewChild('errorCitaSwal') private errorCitaSwal: SwalComponent;

  constructor(private formBuilder: FormBuilder,
    private serviceValidateUser: ValidateUserService,
    private ngxService: NgxUiLoaderService,
    private entidadService: EntidadesService,
    private citaService: CitaService,
    private correoService: CorreoService,
    private router: Router) { }

  ngOnInit() {    
    // Form de folio y CURP
    this.folioForm = this.formBuilder.group({
      fieldFolio: ['', Validators.required],
      fieldCURP: ['', Validators.required]
    }, {
        validators: CURPValidator('fieldCURP')
      });

    // Form de cita
    this.citaForm = this.formBuilder.group({
      fieldName: ['', Validators.required],
      fieldLastName: ['', Validators.required],
      fieldSecondLastName: ['', Validators.required],
      fieldStreet: ['', Validators.required],
      fieldExtNumb: ['', Validators.required],
      fieldIntNumb: [''],
      fieldState: ['', Validators.required],
      fieldTown: ['', Validators.required],
      fieldColony: ['', Validators.required],
      fieldCP: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      fieldTel1: ['', Validators.compose([Validators.required, Validators.minLength(12)])],
      fieldTelType1: ['', Validators.required],
      fieldTel2: ['', Validators.compose([Validators.required, Validators.minLength(12)])],
      fieldTelType2: ['', Validators.required],
      fieldNombreEmg: ['', Validators.required],
      fieldRead: ['', Validators.requiredTrue],
    });

    // Se obtienen las entidades
    this.entidadService.getEntidades().subscribe(res => {
      this.entidadesArray = res.body;
    });

  }

  createCita() {
    this.submitedCita = true;
    if (this.citaForm.invalid) {
      return;
    } else {
      this.ngxService.start();
      this.citaService.createCita(this.getBecarioObj()).subscribe(
        res => {
          this.correoService.createContacto(this.becario).subscribe(
            res2 => {
              this.ngxService.stop();
              this.citaOkSwal.show();
            },
            err2 => {
              this.ngxService.stop();
              this.errorCitaSwal.show();
              console.log(err2);
            }
          );
        },
        err => {
          this.ngxService.stop();
          this.errorCitaSwal.show();
          console.log(err);
        }
      );
    }
  }

  validate() {
    this.submitedFolio = true;

    if (this.folioForm.invalid) {
      return;
    } else {
      this.ngxService.start();
      this.folio = this.folioForm.get('fieldFolio').value;
      this.serviceValidateUser.validateUser(this.folio,
        this.folioForm.get('fieldCURP').value).subscribe(res => {

          if (res.status === 200) {

            this.becario = res.body;
            this.businessUnit = this.becario.empresa.nombreEmpresa;
            this.center = this.becario.nombreCentro;
            this.idPostulacion = this.becario.idPostulacion;
            this.idCentro = this.becario.idCentro;

            // Valida que el folio ingresado esté en estatus 2 (Nuevo)
            if (this.becario.idEstatus === 1 && this.becario.estatusPostulacion === 2) {
              this.validated = true;

              // Disable components
              this.folioForm.get('fieldFolio').disable();
              this.folioForm.get('fieldCURP').disable();
              this.buttonDisabled = true;
              this.ngxService.stop();
              this.citaSwal.show();
            } else if (this.becario.idEstatus === 2) {
              this.ngxService.stop();
              this.declinadoSwal.show();
            } else {
              this.ngxService.stop();
              this.notEnabledSwal.show();
            }

          } else {
            this.ngxService.stop();
            this.notFoundSwal.show();
          }
        }, (err) => {
          console.log(err);
        });
    }
  }

  resolved(event) {
    this.robot = false;
  }

  faqRedirect() {
    this.router.navigateByUrl('/preguntas-frecuentes');
  }

  getBecarioObj() {
    const becario = new Becario();
    becario.direccion = new Direccion();
    becario.telefono1 = new Telefono();
    becario.telefono2 = new Telefono();

    becario.folio = this.folio;
    becario.curp = this.folioForm.get('fieldCURP').value;
    becario.nombre = this.citaForm.get('fieldName').value.toUpperCase();
    becario.apaterno = this.citaForm.get('fieldLastName').value.toUpperCase();
    becario.amaterno = this.citaForm.get('fieldSecondLastName').value.toUpperCase();
    becario.direccion.calle = this.citaForm.get('fieldStreet').value.toUpperCase();
    becario.direccion.numExt = this.citaForm.get('fieldExtNumb').value.toUpperCase();
    becario.direccion.numInt = this.citaForm.get('fieldIntNumb').value.toUpperCase();
    becario.direccion.idEntidad = this.citaForm.get('fieldState').value;
    becario.direccion.municipio = this.citaForm.get('fieldTown').value.toUpperCase();
    becario.direccion.colonia = this.citaForm.get('fieldColony').value.toUpperCase();
    becario.direccion.cp = this.citaForm.get('fieldCP').value;
    becario.telefono1.telefono = this.citaForm.get('fieldTel1').value;
    becario.telefono1.tipo = this.citaForm.get('fieldTelType1').value.toUpperCase();
    becario.telefono2.telefono = this.citaForm.get('fieldTel2').value;
    becario.telefono2.tipo = this.citaForm.get('fieldTelType2').value.toUpperCase();
    becario.avisoPrivacidad = this.citaForm.get('fieldRead').value;
    becario.contacto = this.citaForm.get('fieldNombreEmg').value.toUpperCase();
    becario.idPostulacion = this.idPostulacion;
    becario.idCentro = this.idCentro;

    return becario;
  }

  get f() { return this.folioForm.controls; }

  get c() { return this.citaForm.controls; }
}
