import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TextMaskModule } from 'angular2-text-mask';
import { RecaptchaModule, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CitaComponent } from './components/cita/cita.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { PreguntasFrecuentesComponent } from './components/preguntas-frecuentes/preguntas-frecuentes.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UppercaseDirective } from './directives/uppercase.directive';
import { ContactoComponent } from './components/contacto/contacto.component';
import { EmpresasComponent } from './components/empresas/empresas.component';
import { DeclinacionComponent } from './components/declinacion/declinacion.component';
import { TelefonoPipe } from './pipes/telefono.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    CitaComponent,
    PreguntasFrecuentesComponent,
    UppercaseDirective,
    ContactoComponent,
    EmpresasComponent,
    DeclinacionComponent,
    TelefonoPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RecaptchaModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgxUiLoaderModule,
    TextMaskModule,
    SweetAlert2Module.forRoot(),
    NgbModule
  ],
  providers: [
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'es', // use Spanish language
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
