
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PF} from '../model/PF';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PreguntasFrecuentesServiceService {
  constructor(private https: HttpClient) { }

  apiURL = `${environment.apiURL}catalogos/pf/consulta`;

  getPreguntasFrecuentes() {
    const json = {};

    return this.https.post<PF[]>(this.apiURL, json, {observe: 'response'} ) ;
  }

}
