import { MotivoDeclinacion } from './../model/motivoDeclinacion';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MotivoDeclinacionService {

  apiURL = `${environment.apiURL}catalogos/motivos-declinacion/web-becario`;

  constructor(private http: HttpClient) { }

  getMotivosDeclinacion() {
    const data = {isBecario: true, isDeclinacion: true};
    return this.http.post<MotivoDeclinacion[]>(this.apiURL, data, { observe: 'response' });
  }
}
